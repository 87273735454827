<template>
  <div class="teamee-1">
    <!-- Add Game -->
    <div class="add-game" @click="showComponent('Teamee2')">
      <p>Anfrage hinzufügen</p>
      <img src="@/assets/icons/add.svg" alt="Add" />
    </div>

    <!-- All Game listed here -->
    <div v-if="myGames.length" class="games__wrapper">
      <div class="desc">
        <p>Meine Anfragen:</p>
        <div class="xs">(Zum Löschen auswählen)</div>
      </div>

      <ul class="games" v-click-outside="setGamesToDefault">
        <li v-for="(item, index) in myGames" :key="index">
          <div class="card-game-selection" :class="{ active: index === activeItem }" @click="selectItem(index)">
            <img :src="item.background_image" alt="Game Image" />
            <h4>{{ item.name }}</h4>
          </div>
          <div class="delete" @click="deleteRequest(item.id)">
            <span class="loader" v-if="isLoading"></span>
            <img v-else src="@/assets/icons/delete_dark.svg" alt="Delete" />
          </div>
        </li>
      </ul>
    </div>

    <!-- No Results here -->
    <div v-else class="noResults">
      <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
      <h4>Ziemlich leer hier …</h4>
      <p>Füge ein Spiel hinzu, um passende Mitspieler zu finden.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "teamee-1",
  data() {
    return {
      noResults: false,
      activeItem: null,
      isLoading: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
    selectItem(index) {
      if (this.activeItem == null || this.activeItem !== index) {
        this.activeItem = index;
      } else {
        this.activeItem = null;
      }
    },
    // If User clicks outside a Game Tab, no Game will be selected
    setGamesToDefault() {
      this.activeItem = null;
    },
    deleteRequest(game_id) {
      this.isLoading = true;
      this.$store
        .dispatch("deleteTeameeRequest", { game_id: game_id })
        .then(() => {
          let myGames = this.$store.state.user.my_games;
          const index = myGames.indexOf(game_id);
          if (index > -1) {
            myGames.splice(index, 1);
          }
          // set games null, if last request has been deleted
          if (this.$store.state.user.my_games.length == 0) {
            this.$store.commit("SET_USER_MY_GAMES", null);
            this.$store.state.myGames = [];
          }
          this.$store.dispatch("getMyGames");
          this.activeItem = null;
          this.isLoading = false;
          this.flash("Deine teamee Anfrage wurde erfolgreich gelöscht", "success", { timeout: 3500 });
        })
        .catch(() => {
          this.flash("Oops... Da ist wohl etwas schief gelaufen", "error", { timeout: 3500 });
          this.isLoading = false;
        });
    },
  },
  computed: {
    myGames() {
      return this.$store.state.myGames;
    },
  },
  mounted() {
    // call API for GameInfo
    let gameData = this.$store.getters.MY_GAMES;
    let localStorageGameData = this.$store.getters.USER.my_games;

    if (localStorageGameData !== null) {
      if (gameData.length !== localStorageGameData.length) {
        this.$store.dispatch("getMyGames");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}

.list-leave-active {
  position: absolute;
  width: 100%;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 0.2s;
  transition-delay: 0.3s;
}

.teamee-1 {
  min-height: calc(100vh - 83px);
  padding: 232px 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 92px 24px;
  }
  .add-game {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 800px;
    border: 1px solid $white;
    border-radius: 5px;
    padding: 12px 24px;
    cursor: pointer;
    @media screen and (max-width: 960px) {
      width: 100%;
      margin-top: 64px;
    }
    &:hover {
      background-color: $light-grey;
    }
    img {
      height: 40px;
    }
  }
  .noResults {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    width: 480px;
    @media screen and (max-width: 960px) {
      width: 100%;
      margin-top: 80px;
      max-width: 480px;
    }
    img {
      margin: 0 auto 40px;
      width: 80px;
    }
    h4 {
      @media screen and (max-width: 960px) {
        font-size: 32px;
        line-height: 1.5;
        margin-bottom: 12px;
      }
    }
    p {
      margin-bottom: 64px;
    }
  }
  .games__wrapper {
    margin-top: 32px;
    width: 800px;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .error-msg {
      text-align: center;
    }
    .desc {
      margin-bottom: 8px;
    }
    .games {
      li {
        position: relative;
        list-style-type: none;
        background-color: $darker-navy-blue;
        border-radius: 5px;
        .card-game-selection {
          z-index: 100;
          user-select: none;
          margin-bottom: 8px;
          min-height: 80px;
        }
        .active {
          width: calc(100% - 120px);
          border-radius: 5px 0 0 5px;
          img {
            border-radius: 5px 0 0 5px;
          }
          &:after {
            border-radius: 5px 0 0 5px;
          }
        }
        .delete {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 120px;
          border-radius: 0 5px 5px 0;
          background-color: $white;
          cursor: pointer;
          &:hover {
            background-color: $red;
          }
        }
      }
    }
  }
}
</style>
