<template>
  <div class="chat">
    <div class="top-bar">
      <div class="back" @click="showComponent('TeamArea')">
        <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
        <p>Zurück</p>
      </div>
      <h5>{{ myTeam.team_name }}</h5>
    </div>
    <div class="loader__wrapper" v-if="newDataLoading">
      <span class="loader"></span>
    </div>
    <simplebar data-simplebar-auto-hide="false" data-simplebar class="chat-body" ref="messagesContainer">
      <div class="message" v-for="(item, index) in messages.data" :key="index">
        <div class="avatar__wrapper">
          <img :src="require(`@/assets/icons/${item.user.avatar}`)" alt="Avatar" />
        </div>
        <div class="content__wrapper">
          <div class="info__wrapper">
            <div class="name">{{ item.user.username }}</div>
            <div class="timestamp"><time-ago class="time" :refresh="60" :datetime="item.created_at" tooltip="right" long locale="de"></time-ago></div>
          </div>
          <div class="message">{{ item.message }}</div>
        </div>
        <svg
          class="delete-message"
          v-if="myTeam.admin_id == authId"
          @click="deleteMessage(item, index)"
          width="52px"
          height="64px"
          viewBox="0 0 52 64"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>delete_dark</title>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Desktop-HD" transform="translate(-686.000000, -477.000000)" fill="#484954" fill-rule="nonzero">
              <g id="delete_dark" transform="translate(686.000000, 477.000000)">
                <path
                  d="M48,8 L36,8 L36,4 C36,1.8 34.2,0 32,0 L20,0 C17.8,0 16,1.8 16,4 L16,8 L4,8 C1.8,8 0,9.8 0,12 L0,20 L4.1,20 L6.7,58.4 C6.9,61.5 9.5,64 12.7,64 L39.2,64 C42.3,64 45,61.5 45.2,58.4 L47.9,20 L52,20 L52,12 C52,9.8 50.2,8 48,8 Z M20,4 L32,4 L32,8 L20,8 L20,4 Z M41.3,58.1 C41.2,59.1 40.4,60 39.3,60 L12.7,60 C11.7,60 10.8,59.2 10.7,58.1 L8.1,20 L43.8,20 L41.3,58.1 Z M48,16 L4,16 L4,12 L48,12 L48,16 Z"
                  id="Shape"
                ></path>
                <rect
                  id="Rectangle"
                  transform="translate(16.002059, 39.050820) rotate(85.601492) translate(-16.002059, -39.050820) "
                  x="2.95146645"
                  y="37.0507293"
                  width="26.1011845"
                  height="4.00018153"
                ></rect>
                <rect id="Rectangle" x="24" y="26" width="4" height="26"></rect>
                <rect
                  id="Rectangle"
                  transform="translate(35.997959, 39.051540) rotate(4.396373) translate(-35.997959, -39.051540) "
                  x="33.9978744"
                  y="26.0009853"
                  width="4.00017007"
                  height="26.1011097"
                ></rect>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </simplebar>
    <div class="chat-input">
      <input
        class="input__text__rounded"
        type="text"
        placeholder="Schreibe eine Nachricht"
        title="Input Text"
        v-model="newMessage"
        @keyup.enter="sendMessage"
      />
      <button type="submit" @click="sendMessage">
        <img src="@/assets/icons/send-message.svg" alt="Send" />
      </button>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import TimeAgo from "vue2-timeago";
import api from "@/assets/api/api";
import Echo from "laravel-echo";

export default {
  name: "TeamChat",
  data() {
    return {
      messages: [],
      newMessage: "",
      newDataLoading: false,
    };
  },
  components: {
    simplebar,
    TimeAgo,
  },
  created() {
    this.connect();
  },
  mounted() {
    this.scroll();
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
    connect() {
      if (this.myTeam.chat_room_id) {
        this.getChatMessages();

        window.Pusher = require("pusher-js");
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: "21e8c46664e0574ecddc",
          cluster: "eu",
          forceTLS: true,
          encrypted: true,
          authorizer: (channel) => {
            return {
              authorize: (socketId, callback) => {
                api
                  .post("/broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name,
                  })
                  .then((response) => {
                    callback(false, response.data);
                  })
                  .catch((error) => {
                    callback(true, error);
                  });
              },
            };
          },
        });
        window.Echo.private("user." + this.userId).listen("NewChatMessage", (e) => {
          // push new Message to messages when Event occured
          this.messages.push({
            created_at: e.chatMessage.created_at,
            user: { username: e.author.username, avatar: e.author.avatar },
            message: e.chatMessage.message,
          });
          this.scrollToEnd();
        });
      }
    },
    getChatMessages() {
      this.$store
        .dispatch("getChatMessages", this.myTeam.chat_room_id)
        .then((response) => {
          response.data.data.reverse();
          this.messages = response.data;
          this.scrollToEnd();
        })
        .catch(() => {
          this.flash("Es konnten leider keine Nachrichten geladen werden. Versuche es erneut.", "error", { timeout: 3500 });
        });
    },

    // fetch older Messages, when User scrolls all the way to the Top
    scroll() {
      const simplebar = this.$refs.messagesContainer.SimpleBar.getScrollElement();
      simplebar.addEventListener("scroll", () => {
        let oldHeight = simplebar.scrollHeight;
        if (simplebar.scrollTop == 0 && this.messages.next_page_url !== null && !this.newDataLoading) {
          this.newDataLoading = true;
          this.$store
            .dispatch("loadMoreChatMessages", {
              chat_room_id: this.myTeam.chat_room_id,
              url: this.messages.next_page_url,
            })
            .then((response) => {
              this.newDataLoading = false;
              let newMessages = response.data.data;
              this.messages.next_page_url = response.data.next_page_url; // set new url for next Request

              for (let index = 0; index < newMessages.length; index++) {
                const element = newMessages[index];
                this.messages.data.unshift(element); // push new Data to Messages Array
              }
              this.scrollTo(oldHeight);
            })
            .catch(() => {
              this.newDataLoading = false;
              this.flash("Es konnten leider keine Nachrichten geladen werden. Versuche es erneut.", "error", { timeout: 3500 });
            });
        }
      });
    },
    sendMessage() {
      // if input Field is empty, simply return
      if (this.newMessage == "") {
        return;
      }

      // push new Message immediately in order to have no Downtime
      this.messages.data.push({
        created_at: Date.now(),
        message: this.newMessage,
        user: {
          username: this.$store.state.user.username,
          avatar: this.$store.state.user.avatar,
        },
      });

      this.scrollToEnd(); // scroll down

      let receivers = [];
      for (let index = 0; index < this.myTeam.members.length; index++) {
        let receiver_id = this.myTeam.members[index].id;
        // skip User, who is sending the message, so he won't be notified
        if (receiver_id !== this.userId) {
          receivers.push(receiver_id);
        }
      }

      this.$store
        .dispatch("sendMessage", {
          roomId: this.myTeam.chat_room_id,
          receivers: receivers,
          message: this.newMessage,
        })
        .then(() => {
          this.getChatMessages();
        })
        .catch(() => {
          this.flash("Deine Nachricht konnte leider nicht verschickt werden. Versuche es erneut.", "error", { timeout: 3500 });
        });

      this.newMessage = "";
    },

    // scroll to bottom of Chat Box and when new message is beeing received
    scrollToEnd() {
      // set small Timeout until the new MessagesContainer is rendered
      setTimeout(() => {
        const content = this.$refs.messagesContainer.SimpleBar.getScrollElement();
        content.scrollTo(0, content.scrollHeight);
      }, 1);
    },
    scrollTo(oldHeight) {
      const simplebar = this.$refs.messagesContainer.SimpleBar.getScrollElement();
      setTimeout(() => {
        const newHeight = simplebar.scrollHeight;
        const scrollHeight = newHeight - oldHeight;
        simplebar.scrollTo(0, scrollHeight);
      }, 1);
    },
    deleteMessage(item, index) {
      this.messages.data.splice(index, 1);
      const messageId = item.id;
      this.$store
        .dispatch("deleteMessage", messageId)
        .then(() => {
          this.flash("Nachricht wurde gelöscht.", "success", { timeout: 2500 });
        })
        .catch(() => {
          this.flash("Nachricht konnte nicht gelöscht werden.", "error", { timeout: 2500 });
          this.getChatMessages();
        });
    },
  },
  computed: {
    chatRoomData() {
      return this.$store.getters.CURRENT_CHAT_ROOM_DATA; // contains username and id (if room exists -> also roomId)
    },
    userId() {
      return this.$store.getters.USER.id;
    },
    myTeam() {
      return this.$store.getters.MY_TEAM;
    },
    authId() {
      return this.$store.getters.USER.id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.chat {
  margin-top: 232px;
  width: 800px;
  height: calc(100vh - 400px);
  min-height: 180px;
  max-height: 1200px;
  background-color: $darker-navy-blue;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 0 0 5px 5px;
  padding: 12px;
  @media screen and (max-width: 960px) {
    height: calc(100vh - 148px);
    margin-top: 148px;
    width: 100%;
    padding: 0 12px 0px 12px;
    padding-bottom: 148px;
  }
  @media screen and (max-height: 812px) and (max-width: 900px) {
    padding-bottom: 120px;
  }
  @media screen and (max-height: 568px) {
    padding-bottom: 80px;
  }
  .top-bar {
    position: relative;
    margin-bottom: 12px;
    border-bottom: 1px solid $white;
    .back {
      top: 4px;
      left: 4px;
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 24px;
        margin-right: 16px;
      }
    }
    h5 {
      text-align: center;
      line-height: 2;
      @media screen and (max-width: 960px) {
        text-align: right;
        padding-right: 8px;
      }
    }
  }
  .loader__wrapper {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translateX(-50%);
  }
  .chat-body {
    height: calc(100% - 124px);
    .message {
      display: flex;
      margin-bottom: 12px;
      .avatar__wrapper {
        margin-right: 12px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .content__wrapper {
        display: flex;
        flex-direction: column;
        .info__wrapper {
          display: flex;
          align-items: baseline;
          margin-bottom: 4px;
          .name {
            font-weight: 700;
            font-size: 16px;
            margin-right: 12px;
          }
          .timestamp {
            font-size: 12px;
            color: $grey;
          }
        }
      }
      .delete-message {
        height: 24px;
        margin-left: auto;
        margin-right: 8px;
        align-self: center;
        cursor: pointer;
        &:hover #Desktop-HD {
          fill: #e6e6e6;
        }
      }
      .message {
        font-size: 15px;
        color: #cec6c6;
      }
    }
  }
  .chat-input {
    display: flex;
    padding-top: 12px;
    input {
      line-height: 1;
      flex-grow: 1;
      margin-right: 12px;
      height: 48px;
      background-color: $darker-navy-blue;
    }
    button {
      background-color: transparent;
      border: 1px solid $white;
      border-radius: 5px;
      min-width: 48px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $light-grey;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
