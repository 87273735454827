<template>
  <div class="create-team">
    <div class="content">
      <div class="top-bar">
        <div @click="showComponent('TeamArea')" class="back">
          <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
          <p>Zurück</p>
        </div>
        <h5>Gründe Dein Team!</h5>
      </div>

      <div class="create-team__wrapper">
        <div class="delete-acc">
          <p>Gib Deinem Team einen Namen</p>
          <div class="input__text__label">
            <input
              class="input__text floating-input"
              :class="{ input__text__danger: $v.teamName.$error }"
              placeholder=" "
              type="text"
              title="Team Name"
              v-model.trim="$v.teamName.$model"
            />
            <label>Team Name</label>
          </div>
          <!-- Error Message -->
          <div v-if="$v.teamName.$error" class="error">
            <li v-if="!$v.teamName.checkNamePattern" class="error-msg">Zeichen wie , . _ sind nicht erlaubt</li>
            <li class="error-msg" v-if="!$v.teamName.minLength">Dein Team Name sollte mindestend {{ $v.teamName.$params.minLength.min }} Zeichen enthalten.</li>
            <li class="error-msg" v-if="!$v.teamName.maxLength">
              Dein Team Name darf nicht mehr als {{ $v.teamName.$params.maxLength.max }} Zeichen enthalten.
            </li>
          </div>
        </div>
        <button class="button__cta" @click="createTeam">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>Team Gründen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "CreateTeam",
  data() {
    return {
      teamName: "",
      isLoading: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
    createTeam() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        // if Form is valid -> create Team
        this.$store
          .dispatch("createTeam", {
            team_name: this.teamName,
          })
          .then(async () => {
            await this.$store
              .dispatch("getMyTeam")
              .then(() => {
                this.isLoading = false;
                this.flash("Du hast ein Team gegründet! Du kannst nun Freunde einladen oder den Team Chat nutzen!", "success", { timeout: 5000 });
                this.$store.commit("SET_TEAMEE_COMPONENT", "TeamArea");
              })
              .catch(() => {
                this.isLoading = false;
                this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
              });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.status === 422) {
              this.flash(error.response.data.errors.team_name[0], "error", { timeout: 3500 });
            }
            if (error.response.status === 403) {
              this.flash(error.response.data.msg, "error", { timeout: 3500 });
            }
          });
      }
    },
  },
  validations: {
    teamName: {
      minLength: minLength(4),
      maxLength: maxLength(20),
      checkNamePattern(teamName) {
        return /^[a-zA-Z0-9]+$/.test(teamName); // check for letters and numbers only
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.create-team {
  min-height: calc(100vh - 83px);
  padding: 0 24px;
  padding-top: 130px;
  display: flex;
  background-color: $darker-navy-blue;
  @media screen and (max-width: 960px) {
    padding: 100px 24px;
  }
  .content {
    width: 752px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .top-bar {
      border-bottom: 1px solid #979797;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: sticky;
      top: 132px;
      z-index: 100;
      background-color: $darker-navy-blue;
      padding-top: 108px;
      @media screen and (max-width: 960px) {
        padding: 10px 0;
        top: 92px;
      }
      .back {
        left: 0;
        position: absolute;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        img {
          height: 24px;
          margin-right: 16px;
        }
      }
      h5 {
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
    }
    .create-team__wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      .delete-acc {
        margin-top: 24px;
        .input__text__label {
          margin-top: 20px;
          @media screen and (max-width: 960px) {
            margin-top: 60px;
          }
        }
        .error {
          margin-top: 8px;
          margin-left: 16px;
          .error-msg {
            font-size: 14px;
            color: $red;
          }
        }
      }
    }
    .button__cta {
      width: 400px;
      align-self: center;
      margin-top: auto;
      margin-bottom: 80px;
      outline: none;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
