<template>
  <div class="team-area">
    <div class="has-team__wrapper" v-if="myTeam">
      <h4>{{ myTeam.team_name }}</h4>

      <!-- Add Game -->
      <div class="team-chat" @click="showComponent('TeamChat')">
        <p>Team Chat</p>
        <img src="@/assets/icons/write-message_white.svg" alt="Team Chat" />
      </div>

      <p class="headline-members">
        Team Mitglieder: <span>{{ myTeam.members.length }}/10</span>
      </p>
      <simplebar data-simplebar-auto-hide="false" class="new-teamee-request members">
        <div class="add-member request" v-if="myTeam.members.length < 10 && myTeam.admin_id == authId" @click="showComponent('AddTeamMember')">
          <p>Teammitglied hinzufügen</p>
          <img src="@/assets/icons/add.svg" alt="Add Member" />
        </div>
        <transition-group name="list" tag="div">
          <div class="request" v-for="(item, index) in myTeam.members" :key="index">
            <div class="info">
              <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
              <p>{{ item.username }}</p>
            </div>
            <div class="btn-group" v-if="myTeam.admin_id == authId && myTeam.admin_id !== item.id">
              <img class="btn" src="@/assets/icons/decline.svg" alt="Decline" @click="deleteTeamMember(index, item)" />
            </div>
            <div class="btn-group admin" v-if="myTeam.admin_id == item.id">
              <img class="btn" src="@/assets/icons/crown.svg" alt="Admin" />
            </div>
          </div>
        </transition-group>
      </simplebar>

      <!-- Delete Team -->
      <div class="btn-delete-team__wrapper" v-if="myTeam.admin_id == authId">
        <p v-if="attemptDeletion">Team wirklich löschen?</p>
        <div class="btn__wrapper">
          <button class="btn abort button__transparent" @click="abortAttempt" v-if="attemptDeletion">
            <span class="txt">Abbrechen</span>
          </button>
          <button class="btn" :class="attemptDeletion ? 'button__danger' : 'button__transparent'" @click="deleteTeam">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Team löschen</span>
          </button>
        </div>
      </div>
      <!-- Leave Team (only for Non-Admin Members) -->
      <div class="btn-leave-team__wrapper" v-else>
        <p v-if="attemptDeletion">Team wirklich verlassen?</p>
        <div class="btn__wrapper">
          <button class="btn abort button__transparent" @click="abortAttempt" v-if="attemptDeletion">
            <span class="txt">Abbrechen</span>
          </button>
          <button class="btn" :class="attemptDeletion ? 'button__danger' : 'button__transparent'" @click="leaveTeam(0, { id: authId })">
            <span class="loader" v-if="isLoading"></span>
            <span class="txt" v-else>Team verlassen</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Display if User has no Team -->
    <div class="no-team__wrapper" v-else>
      <simplebar data-simplebar-auto-hide="false" class="new-teamee-request" v-if="teamInvites.length">
        <p>Neue Team Einladung!</p>
        <transition-group name="list" tag="div">
          <div class="request" v-for="(item, index) in teamInvites" :key="index">
            <div class="info">
              <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
              <p>{{ item.username }} | Team: {{ item.team_name }}</p>
            </div>
            <div class="btn-group">
              <img class="btn" src="@/assets/icons/accept.svg" alt="Accept" @click="acceptTeamInvite(index, item.id)" />
              <img class="btn" src="@/assets/icons/decline.svg" alt="Decline" @click="declineTeamInvite(index, item.id)" />
            </div>
          </div>
        </transition-group>
      </simplebar>

      <!-- create Team -->
      <div class="create-team" @click="showComponent('CreateTeam')">
        <p>Neues Team gründen</p>
        <img src="@/assets/icons/add.svg" alt="Add" />
      </div>
      <!-- No Results here -->
      <div class="noResults">
        <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
        <h4>Du bist in keinem Team …</h4>
        <p>Gründe ein neues Team oder schließe dich einem bereits bestehenden Team an!</p>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "TeameArea",
  components: {
    simplebar,
  },
  data() {
    return {
      isLoading: false,
      attemptDeletion: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
    acceptTeamInvite(index, team_id) {
      this.$store
        .dispatch("acceptTeamInvite", {
          team_id: team_id,
        })
        .then(() => {
          this.$store.getters.MY_TEAM_INVITES.splice(index, 1);
          this.$store.dispatch("getMyTeam");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.flash(error.response.data.msg, "error", { timeout: 3500 });
          } else {
            this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
          }
        });
    },
    declineTeamInvite(index, team_id) {
      this.$store
        .dispatch("declineTeamInvite", {
          team_id: team_id,
        })
        .then(() => {
          this.$store.getters.MY_TEAM_INVITES.splice(index, 1);
          this.$store.dispatch("getMyTeam");
        })
        .catch(() => {
          this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
        });
    },
    deleteTeamMember(index, item) {
      this.myTeam.members.splice(index, 1);
      this.$store
        .dispatch("deleteTeamMember", {
          user_id: item.id,
          team_id: this.myTeam.id,
          chat_room_id: this.myTeam.chat_room_id,
        })
        .then(() => {
          this.$store.dispatch("getMyTeam");
        })
        .catch(() => {
          this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
        });
    },
    leaveTeam(index, item) {
      if (this.attemptDeletion) {
        console.log(item);
        this.$store
          .dispatch("deleteTeamMember", {
            user_id: item.id,
            team_id: this.myTeam.id,
            chat_room_id: this.myTeam.chat_room_id,
          })
          .then(() => {
            this.$store.dispatch("getMyTeam");
          })
          .catch(() => {
            this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
          });
      }
      this.attemptDeletion = true;
    },
    abortAttempt() {
      this.attemptDeletion = false;
    },
    deleteTeam() {
      if (this.attemptDeletion) {
        this.isLoading = true;
        this.$store
          .dispatch("deleteTeam", {
            admin_id: this.myTeam.admin_id,
            team_id: this.myTeam.id,
            chat_room_id: this.myTeam.chat_room_id,
          })
          .then(() => {
            this.isLoading = false;
            this.$store.state.myTeam = null;
            this.flash("Dein Team wurde erfolgreich gelöscht", "success", { timeout: 3500 });
          })
          .catch(() => {
            this.isLoading = false;
            this.flash("Es ist ein Fehler aufgetreten", "error", { timeout: 3500 });
          });
      }
      this.attemptDeletion = true;
    },
  },
  mounted() {
    this.$store.dispatch("getMyTeam");
  },
  computed: {
    myTeam() {
      return this.$store.getters.MY_TEAM;
    },
    authId() {
      return this.$store.getters.USER.id;
    },
    teamInvites() {
      return this.$store.getters.MY_TEAM_INVITES;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.team-area {
  min-height: calc(100vh - 83px);
  padding: 232px 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 132px 24px 100px;
    align-items: unset;
  }
  .has-team__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    h4 {
      text-align: center;
      line-height: 1.5;
      margin-top: 20px;
    }
    .game-name {
      text-align: center;
      margin-bottom: 20px;
    }
    .team-chat {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 800px;
      border: 1px solid $white;
      border-radius: 5px;
      padding: 12px 24px;
      cursor: pointer;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
      &:hover {
        background-color: $light-grey;
      }
      img {
        height: 32px;
      }
    }
    .new-teamee-request {
      max-height: 120px;
      margin-bottom: 32px;
    }
    .headline-members {
      margin-top: 20px;
    }
    .btn-delete-team__wrapper,
    .btn-leave-team__wrapper {
      margin-top: auto;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        text-align: center;
      }
      .btn__wrapper {
        display: flex;
        width: 100%;
        .abort {
          margin-right: 40px;
          &:hover {
            background-color: $green;
          }
        }
        .btn {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .members {
      max-height: 310px;
      .request {
        .btn-group {
          .btn {
            width: 36px;
            height: 36px;
            cursor: pointer;
            &:first-of-type {
              margin-right: 0px;
            }
            &:active {
              filter: brightness(50%);
            }
          }
        }
        .admin {
          .btn {
            cursor: auto;
          }
        }
      }
      .add-member {
        cursor: pointer;
        &:hover {
          background-color: $light-grey;
        }
        img {
          height: 36px;
          width: 36px;
        }
      }
    }
  }
  .no-team__wrapper {
    .create-team {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 800px;
      border: 1px solid $white;
      border-radius: 5px;
      padding: 12px 24px;
      cursor: pointer;
      @media screen and (max-width: 960px) {
        width: 100%;
      }
      &:hover {
        background-color: $light-grey;
      }
      img {
        height: 40px;
      }
    }
    .noResults {
      margin: 80px auto 0;
      display: flex;
      flex-direction: column;
      width: 480px;
      @media screen and (max-width: 960px) {
        width: 100%;
        margin-top: 20px;
        max-width: 480px;
      }
      img {
        width: 80px;
        margin: 0 auto 40px;
        @media screen and (max-width: 960px) {
          margin-bottom: 24px;
        }
      }
      h4 {
        @media screen and (max-width: 960px) {
          font-size: 24px;
          line-height: 1.5;
          margin-bottom: 12px;
        }
      }
      p {
        margin-bottom: 64px;
        line-height: 1.5;
      }
    }
  }

  .new-teamee-request {
    width: 800px;
    margin-bottom: 16px;
    max-height: 220px;
    overflow: auto;
    @media screen and (max-width: 960px) {
      max-height: 120px;
      width: 100%;
    }
    .request {
      width: calc(100% - 16px);
      border: 1px solid $white;
      padding: 6px 12px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .info {
        display: flex;
        img {
          height: 40px;
          width: 40px;
          margin-right: 12px;
        }
      }
      .btn-group {
        display: flex;
        .btn {
          width: 36px;
          height: 36px;
          cursor: pointer;
          &:first-of-type {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}

.list-leave-active {
  position: absolute;
  width: 100%;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 0.2s;
  transition-delay: 0.3s;
}
</style>
