<template>
  <main>
    <div class="content">
      <div class="top-bar">
        <div class="back" @click="showComponent('Teamee2')">
          <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
          <p>Zurück</p>
        </div>
      </div>
      <form>
        <!-- Search Mode: LFG / LFM -->
        <div class="section col">
          <div class="desc">Suchmodus</div>
          <div class="input">
            <div class="tab-navigation">
              <div class="lfg" @click="activeSearchModeTab = 'lfg'" :class="{ active: activeSearchModeTab === 'lfg' }">
                LFG
              </div>
              <div class="lfm" @click="activeSearchModeTab = 'lfm'" :class="{ active: activeSearchModeTab === 'lfm' }">
                LFM
              </div>
            </div>
            <div class="search-mode__wrapper">
              <div class="lfg-desc" v-if="activeSearchModeTab === 'lfg'">
                <p class="desc">LFG (Looking for Group) : Ich suche nach einer bereits bestehenden Gruppe von Mitspielern</p>
              </div>

              <div class="lfm-desc" v-else>
                <div class="lfm-wrapper">
                  <p class="desc">LFM (Looking for Members) : Meine bereits bestehende Gruppe sucht nach weiteren Mitspielern</p>
                  <div class="input">
                    Anzahl Mitspieler:
                    <select name="lfm" class="dropdown" v-model="player_count">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Platform -->
        <div class="section">
          <div class="desc">Platform</div>
          <div class="input">
            <div class="platform__options">
              <label for="platform__1" @click="platform = 'pc'"
                >PC
                <input id="platform__1" value="pc" type="radio" name="platform" />
                <span class="checkmark"></span>
              </label>

              <label for="platform__2" @click="platform = 'ps'"
                >PlayStation
                <input id="platform__2" value="ps" type="radio" name="platform" />
                <span class="checkmark"></span>
              </label>

              <label for="platform__3" @click="platform = 'xb'"
                >Xbox
                <input id="platform__3" value="xb" type="radio" name="platform" />
                <span class="checkmark"></span>
              </label>

              <label for="platform__4" @click="platform = 'mb'"
                >Mobile
                <input id="platform__4" value="mb" type="radio" name="platform" />
                <span class="checkmark"></span>
              </label>

              <label for="platform__4" @click="platform = 'ns'"
                >Nintendo Switch
                <input id="platform__4" value="ns" type="radio" name="platform" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>

        <!-- Dropdown Language  -->
        <div class="section">
          <div class="desc">Sprache</div>
          <div class="input">
            <select name="lang" class="dropdown" v-model="language">
              <option value="de">Deutsch</option>
              <option value="en">Englisch</option>
              <option value="fr">Französisch</option>
              <option value="es">Spanisch</option>
              <option value="it">Italienisch</option>
            </select>
          </div>
        </div>

        <!-- Dropdown Skills -->
        <div class="section">
          <div class="desc">Könnensstufe</div>
          <div class="input">
            <select name="skill" class="dropdown" v-model="skill_tier">
              <option value="0">Anfänger</option>
              <option value="1">Fortgeschritten</option>
              <option value="2">Könner</option>
              <option value="3">Profi</option>
            </select>
          </div>
        </div>

        <!-- Game Mode -->
        <div class="section col">
          <div class="desc">Game Modus</div>
          <div class="input">
            <div class="tab-navigation">
              <div class="casual" @click="activeGameModeTab = 'casual'" :class="{ active: activeGameModeTab === 'casual' }">
                Casual
              </div>
              <div class="competitive" @click="activeGameModeTab = 'competitive'" :class="{ active: activeGameModeTab === 'competitive' }">
                Competitive
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="section col">
          <div class="desc">Beschreibung</div>
          <div class="input">
            <textarea name="description" placeholder="Individualisiere deine Anfrage!" maxlength="500" v-model="description">
Hey, Ich suche nach Mitspielern!</textarea
            >
          </div>
        </div>

        <button class="button__cta" @click.prevent="setGameConfigs" @keypress.enter.prevent :disabled="isLoading">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>Team Me!</span>
        </button>
      </form>
    </div>
  </main>
</template>

<script>
export default {
  name: "Teamee-3",
  data() {
    return {
      activeSearchModeTab: "lfg",
      player_count: "1",
      platform: "",
      language: "de",
      skill_tier: "0",
      activeGameModeTab: "casual",
      description: "",
      isLoading: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
    setGameConfigs() {
      this.$store.state.gameConfigs.search_mode = this.activeSearchModeTab;
      this.$store.state.gameConfigs.player_count = this.player_count;
      this.$store.state.gameConfigs.platform = this.platform;
      this.$store.state.gameConfigs.language = this.language;
      this.$store.state.gameConfigs.skill_tier = this.skill_tier;
      this.$store.state.gameConfigs.game_mode = this.activeGameModeTab;
      this.$store.state.gameConfigs.description = this.description;

      this.isLoading = true;
      // gameConfigs have to be stored in DB

      this.$store
        .dispatch("addTeameeRequest")
        .then((response) => {
          this.$store.commit("SET_USER_MY_GAMES", response.data);
          this.isLoading = false;
          this.$store.commit("SET_TEAMEE_COMPONENT", "Teamee1");
          this.flash("Deine teamee Anfrage wurde erfolgreich erstellt", "success", { timeout: 3500 });
        })
        .catch((error) => {
          this.isLoading = false;
          this.flash(error.response.data.msg, "error", { timeout: 3500 });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  min-height: calc(100vh - 83px);
  padding: 0 24px;
  background-color: $darker-navy-blue;
  @media screen and (max-width: 960px) {
    padding: 136px 24px 100px;
  }
  .content {
    width: 752px;
    margin: 0 auto;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .top-bar {
      border-bottom: 1px solid #979797;
      padding-bottom: 8px;

      position: sticky;
      top: 132px;
      z-index: 100;
      background-color: $darker-navy-blue;
      padding-top: 118px;
      @media screen and (max-width: 960px) {
        padding: 10px 0;
        top: 132px;
      }
      .back {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
        @media screen and (max-width: 960px) {
          margin-bottom: 0;
        }
        &:hover {
          text-decoration: underline;
        }
        img {
          height: 24px;
          margin-right: 16px;
        }
      }
    }
    form {
      margin-top: 180px;
      padding-bottom: 24px;
      @media screen and (max-width: 960px) {
        margin-top: 20px;
      }
      .section {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        .desc {
          font-size: 17px;
        }
      }
      .col {
        flex-direction: column;
        .desc {
          margin-bottom: 8px;
        }
        textarea {
          width: 100%;
          height: 160px;
          background-color: $darker-navy-blue;
          border-radius: 5px;
          resize: none;
          font-size: 17px;
          line-height: 1.5;
          padding: 8px 12px;
          border: 1px solid $white;
        }
        .tab-navigation {
          width: 100%;
          z-index: 100;
          display: flex;
          align-items: flex-end;
          .casual,
          .competitive,
          .lfg,
          .lfm {
            width: 50%;
            height: 40px;
            border: 1px solid $white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: relative;
          }
          .casual,
          .lfg {
            border-radius: 5px 0 0 5px;
          }
          .competitive,
          .lfm {
            border-radius: 0 5px 5px 0;
          }
        }
        .search-mode__wrapper {
          margin-top: 12px;
          .desc {
            line-height: 1.5;
            font-size: 15px;
          }
          .lfm-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .input {
              margin-left: 24px;
              width: 174px;
              @media screen and (max-width: 600px) {
                margin-left: 8px;
              }
            }
            .dropdown {
              margin-top: 8px;
            }
          }
        }
      }
      .error-msg {
        margin-bottom: 24px;
        text-align: center;
      }
    }
    .button__cta {
      width: 100%;
    }
  }
}

.active {
  background: $gradient;
}
</style>
