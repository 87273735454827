<template>
  <div class="teamee">
    <main>
      <div class="tab-navigation">
        <div
          class="teamee"
          @click="showComponent('Teamee1')"
          :class="{ active: component === 'Teamee1' || component === 'Teamee2' || component === 'Teamee3' }"
        >
          Team Me!
        </div>
        <div
          class="team-area"
          @click="showComponent('TeamArea')"
          :class="{ active: component === 'TeamArea' || component === 'TeamChat' || component === 'CreateTeam' || component === 'AddTeamMember' }"
        >
          Team Area
          <div class="notification" v-if="teamInvites.length">+{{ teamInvites.length }}</div>
        </div>
      </div>

      <component :is="component" />
    </main>
  </div>
</template>

<script>
import Teamee1 from "@/components/Teamee-1.vue";
import Teamee2 from "@/components/Teamee-2.vue";
import Teamee3 from "@/components/Teamee-3.vue";
import TeamArea from "@/components/TeamArea.vue";
import CreateTeam from "@/components/CreateTeam.vue";
import TeamChat from "@/components/TeamChat.vue";
import AddTeamMember from "@/components/AddTeamMember.vue";

export default {
  name: "Teamee",
  components: {
    Teamee1,
    Teamee2,
    Teamee3,
    TeamArea,
    CreateTeam,
    TeamChat,
    AddTeamMember,
  },
  data() {
    return {
      newRequest: true,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
  },
  computed: {
    component() {
      return this.$store.getters.TEAMEECOMPONENT;
    },
    teamInvites() {
      return this.$store.getters.MY_TEAM_INVITES;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.teamee {
  main {
    min-height: calc(100vh - 315px);
    width: 800px;
    margin: auto;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .tab-navigation {
      width: 800px;
      height: 100px;
      background-color: $navy-blue;
      z-index: 200;
      display: flex;
      align-items: flex-end;
      top: 132px;
      position: fixed;
      @media screen and (max-width: 960px) {
        top: 92px;
        height: 40px;
        padding: 0 24px;
        width: 100%;
      }
      .teamee,
      .team-area {
        width: 50%;
        height: 40px;
        border: 1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
      }
      .teamee {
        border-radius: 5px 0 0 5px;
      }
      .team-area {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.active {
  background-color: $light-grey;
}

.notification {
  width: 34px;
  height: 24px;
  position: absolute;
  background: $gradient;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  right: 10px;
  @media screen and (max-width: 960px) {
    right: 4px;
  }
}
</style>
