<template>
  <main>
    <div class="content">
      <div class="top-bar">
        <div @click="showComponent('Teamee1')" class="back">
          <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
          <p>Zurück</p>
        </div>
        <h5>Wähle ein Spiel</h5>
        <div class="search-input__wrapper">
          <input
            type="search"
            class="input__text__rounded"
            placeholder="Suchen ..."
            title="Nach Spielen suchen"
            v-model="searchQuery"
            @keyup="searchForGames"
          />
        </div>
      </div>
      <div class="games">
        <div class="popular-games" v-if="!searchResults.length">
          <div v-for="(item, index) in games" :key="index" @click="setGameConfigs(item.id, 'Teamee3')" class="card-game-selection">
            <img :src="item.background_image" alt="Hero Bild" />
            <h4>{{ item.name }}</h4>
          </div>
        </div>
        <div class="search-results" v-else>
          <div v-for="(item, index) in searchResults" :key="index" @click="setGameConfigs(item.id, 'Teamee3')" class="card-game-selection">
            <img :src="item.background_image" alt="Hero Bild" />
            <h4>{{ item.name }}</h4>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import axios from "axios";

export default {
  name: "Teamee-2",
  data() {
    return {
      searchResults: [],
      searchQuery: "",
    };
  },
  computed: {
    games() {
      return this.$store.state.popularGames;
    },
  },
  methods: {
    // Search for specific Game
    searchForGames() {
      setTimeout(this.axiosRequest, 1000); // After stop typing for 1 Second, the Request is made
    },
    axiosRequest() {
      axios
        .get(`https://api.rawg.io/api/games?key=34416eb7c9e34bbbb81b9890ca4ba394&page_size=5&search=${this.searchQuery}`, {
          headers: { "User-Agent": "teamee" },
        })
        .then((response) => {
          this.searchResults = response.data.results;
        });
    },
    setGameConfigs(id, component) {
      this.$store.state.gameConfigs.game_id = id; // store gameID in VUEX
      this.$store.commit("SET_TEAMEE_COMPONENT", component); // display next Component
    },
    showComponent(name) {
      this.$store.commit("SET_TEAMEE_COMPONENT", name);
    },
  },
  mounted() {
    this.$store.dispatch("getPopularGames");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

main {
  min-height: calc(100vh - 83px);
  padding: 0 24px;
  background-color: $darker-navy-blue;
  @media screen and (max-width: 960px) {
    padding: 136px 24px 100px;
  }
  .content {
    width: 752px;
    margin: 0 auto;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .top-bar {
      border-bottom: 1px solid #979797;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      position: sticky;
      top: 132px;
      z-index: 100;
      background-color: $darker-navy-blue;
      padding-top: 108px;
      @media screen and (max-width: 960px) {
        padding: 10px 0;
        top: 132px;
      }
      .back {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        img {
          height: 24px;
          margin-right: 16px;
        }
      }
      h5 {
        @media screen and (max-width: 960px) {
          display: none;
        }
      }
      .search-input__wrapper {
        input {
          height: 40px;
          border-radius: 20px;
          width: 120px;
          &:focus {
            width: 232px;
          }
        }
      }
    }
    .games {
      margin-top: 132px;
      padding: 12px 0;
      @media screen and (max-width: 960px) {
        margin-top: 0px;
      }
      .card-game-selection {
        margin-bottom: 8px;
        height: 80px;
        width: 100%;
      }
      h4 {
        @media screen and (max-width: 960px) {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
